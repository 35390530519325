import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { SliceZone } from '@prismicio/react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
 
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
 
import Layout from '@components/layout'
import embedLink from '../components/embed-link'
import { components } from '../slices/index'
import '../styles/webinar.scss'
 
const WebinarPage = ({ data }) => {
 let colWidth = 12
 let centerVid = null
//   if (data.prismicWebinar.data.speakers_group[0].speaker.document == null) {
//    colWidth = 12
//    centerVid = null
//    speakers = false
//  }
  return (
   <div>
     <Layout pageTitle={data.prismicWebinar.data.title.text}>
       <main>
         <Container fluid="xxl" className="text-center mb 1">
           <Row>
             <Col>
               <div>
                 <h1 className="webinar-h1">{data.prismicWebinar.data.title?.text}</h1>
                 <h2 className="webinar-h2">{data.prismicWebinar.data?.webinar_date}</h2>
               </div>
             </Col>
           </Row>
           <Row xl={2} lg={2} md={2} sm={1} xs={1}>
             <Col className={centerVid} xl={colWidth} lg={colWidth} md={colWidth}>
               <iframe
                 id="webinar-video"
                 frameborder="0"
                 src={embedLink(data.prismicWebinar.data.embed_webinar.embed_url)}
                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                 allowfullscreen></iframe>

                <div style={{textAlign: 'center'}}>
                  <PrismicRichText field={data.prismicWebinar.data.description?.richText} />
                </div>
             </Col>
             {/* {speakers
             ?<Col className="speaker-comp" xl={12-colWidth} lg={12-colWidth} md={12-colWidth}>              
               <Container>
               {data.prismicWebinar.data.speakers_group.map(({ speaker }) => (
                   <Row>
                     <Col>
                       <GatsbyImage className="speaker-image" image={speaker.document.data.speaker_img?.gatsbyImageData} />
                       <h1>{speaker.document.data.name.text}</h1>
                      
                       <div className="speaker-info">
                         <PrismicRichText field={speaker.document.data.description?.richText} />
                       </div>
                     </Col>
                   </Row>
               ))}
               </Container>
             </Col>
             : ""
               } */}
           </Row>
         </Container>
         <Container>
           <Row className="align-items-center mt-4">
             <Col>
               <SliceZone slices={data.prismicWebinar.data.body} components={components}></SliceZone>
             </Col>
           </Row>
         </Container>
       </main>
     </Layout>
   </div>
 )
}
 
export const query = graphql`
query($id: String) {
 prismicWebinar(id: {eq: $id}) {
  _previewable
   data {
     speakers_group {
       speaker {
         document {
           ... on PrismicSpeaker {
             id
             data {
               speaker_img {
                 gatsbyImageData
               }
               name {
                 text
               }
               description {
                 richText
               }
             }
           }
         }
       }
     }
     body {
       ... on PrismicWebinarDataBodyInfoBanner {
         id
         slice_type
         primary {
           title_text {
             richText
           }
           text {
             text
           }
           button_text {
             text
           }
           button_link {
             url
            document {
              ... on PrismicWebinar {
                _previewable
                id
                uid
                internal {
                  type
                }
              }
              ... on PrismicPage {
                _previewable
                id
                uid
                data {
                  title {
                    text
                  }
                }
                internal {
                  type
                }
              }
              ... on PrismicCaseStudy {
                _previewable
                id
                internal {
                  type
                }
                uid
              }
              ... on PrismicArticle {
                _previewable
                id
                internal {
                  type
                }
                uid
              }
            }
           }
         }
       }
       ... on PrismicWebinarDataBodySpeaker {
          id
          slice_type
          primary {
            speakers_title {
              richText
            }
          }
          items {
            speaker_photo {
              richText
            }
            speaker_info {
              richText
            }
            speaker_name {
              text
            }
          }
        }
     }
     webinar_date(formatString: "MMM DD, YYYY")
     title {
       text
     }
     thumbnail {
       gatsbyImageData
     }
     embed_webinar {
       embed_url
     }
     description {
       richText
     }
   }
 }
}`
 
 
export default withPrismicPreview(WebinarPage)

