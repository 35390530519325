import * as React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { SliceButton } from '../../components/slice-button'

export const TextOverVideo = ({ slice }) => {
  return (
    <Container xxl>
      <Row>
        <Col className="no-gutters">
          <div className="hero-banner large-hero">
            <div className="hero-image">
              <video autoPlay loop muted playsInline preload="metadata" width="100%">
                <source src={slice.primary.video.url} type="video/mp4"/>
                  Your browser does not support videos
              </video>
            </div>
            <div className="hero-fade"></div>
            <div className="hero-text">
              <h1>{slice.primary.slice_title.text}</h1>
              <p>{slice.primary.slice_text.text}</p>
              {slice.primary.button_text.text
                ?<SliceButton button_link={slice.primary.button_link}
                  button_text={slice.primary.button_text.text}
                  >
                </SliceButton>
                :""
              }   
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}