import * as React from 'react'
import { Link } from 'gatsby'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

const WastewaterAudit = () => {
  return (
    <Container fluid className="wastewater-audit-module bg-gray">
      <Container fluid="xxl">
        <Row xl={2} lg={2} md={2} sm={1} xs={1}>
          <Col className="text-center">
            <div class="module-half">
              <h1>
                LEARN HOW TO DO A WASTEWATER AUDIT
              </h1>
              <p>
                Our 10-step checklist will teach you how to do it all from assembling
                the right team to reporting for maximum results (and recognition).
              </p>
              <Link to="/misc/wastewater-audit-checklist">
                <Button className="shadow">GET THE CHECKLIST</Button>
              </Link>
            </div>
          </Col>
          <Col className="text-center">
            <div class="module-half">
              <h1>
                LET US DO THE AUDIT FOR YOU
              </h1>
              <p>
                All it takes is a candid conversation at your facility where we learn about your processes, problems and goals. We'll take some samples for analysis, and return with recommendations.
              </p>
              <Link to="/misc/free-wastewater-audit">
                <Button className="shadow">BOOK YOUR AUDIT</Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default WastewaterAudit