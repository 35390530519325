export function textAlign(text_align) {
  switch (text_align) {
    case 'Left':
      return('text-left')
    case 'Right':
      return('text-right')
    case 'Center':
      return('text-center')
    default:
      return('text-left')
  }
}