import * as React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

export const iFrameSlice = ({ slice }) => {
    const title = slice.primary.iframe_title.text;
    const url = slice.primary.url.text;

    const iFrame = <iframe
    id="careers-iframe"
    className="iframe-container"
    title={title}
    src={url}
    allowfullscreen=""
    loading="lazy"
  ></iframe>;

  return (
<Container fluid="xxl">
  <Row>
  <Col>
      <div>{iFrame}</div>
    </Col>
  </Row>
</Container>
  )
}