import { PrismicRichText } from '@prismicio/react'
import * as React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'


export const Speaker = ({ slice }) => {
  const accordionStyle = {
    width: '300px',
    height: '225px'
  }

  return (
    <>
    <div style={{textAlign: 'center'}}>
        <PrismicRichText field={slice.primary.speakers_title?.richText} />
    </div>
  
    <Row>

            {slice.items.map(({ speaker_photo, speaker_info, speaker_name }) => (
                <Col>
                <PrismicRichText field={speaker_photo?.richText} />
                <Accordion defaultActiveKey='0' style={accordionStyle}>
                <Accordion.Item>
                    <Accordion.Header onClick={(e) => e.currentTarget.blur()}>{speaker_name?.text}</Accordion.Header>
                    <Accordion.Body className='ps-5'>
                            <div class="accordion-contact">
                            <PrismicRichText field={speaker_info?.richText} />
                            </div>
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion>
                </Col>
            ))}
        
    </Row>
    </>
  )
}

{/* <Container fluid="xxl" className="section">
            <Row xl={2} lg={2} md={1} sm={1} xs={1}>
                <Col md>
                    <div style={{ textAlign: dropDownAlign }}>
                        <Accordion defaultActiveKey='1'>
                            {slice.items.map(({ drop_down_field_title, drop_down_content }) => (
                                count++,
                                <Accordion.Item eventKey={count.toString()}>
                                    <Accordion.Header onClick={(e) => e.currentTarget.blur()}>{drop_down_field_title.text}</Accordion.Header>
                                    <Accordion.Body className='ps-5'>
                                        <Container fluid="xxl">
                                            <ListGroup variant="flush">
                                                <ListGroup.Item>
                                                    <div class="accordion-contact">
                                                    <PrismicRichText field={drop_down_content.richText} />
                                                    </div>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Container>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                </Col>
                <Col md>
                    <div dangerouslySetInnerHTML={{ __html: contactIFrame }} />
                </Col>
            </Row>
        </Container> */}