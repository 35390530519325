import * as React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { SliceButton } from '@components/slice-button'
import { linkResolver } from '../../linkresolver'

export const ArticlesAndEnews = ({ slice }) => {
  return (
    <Container fluid='xxl' id='wessuc-e-news'>
      <Row lg={2} md={1} sm={1} xs={1}>
        <Col>
          <PrismicRichText field={slice.primary.slice_title.richText}></PrismicRichText>
          <p>
            {slice.primary.slice_text.text}
          </p>
          {slice.primary.button_text.text
              ?<SliceButton button_link={slice.primary.button_link}
                button_text={slice.primary.button_text.text}
                colour="red"
                >
              </SliceButton>
              :""
            }
        </Col>
        <Col>
        {slice.items.map(({ articles }) => (
          <Link to={linkResolver(articles.document)} className="hero-banner article-hero">
            <GatsbyImage
              image={articles.document.data.article_thumbnail.gatsbyImageData}
              className="hero-image"
            />
            <div className="hero-text">
              <div>{articles.document.data.title.text}</div>
            </div>
          </Link>
        ))}
        </Col>
      </Row>
    </Container>
  )
}