import * as React from 'react'
import { Link } from 'gatsby'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

const MustRead = () => {
  return (
    <Container fluid className="must-read">
      <Container fluid="xxl">
        <Row xl={2} lg={2} md={2} sm={1} xs={1} className="align-items-center">
          <Col xl={9} lg={9}>
            <h2>MUST READ</h2>
            <h1>23 questions about your company's wastewater you can't afford to ignore</h1>
          </Col>
          <Col xl={3} lg={3} className="text-right">
            <Link to="/articles/23-questions-about-your-companys-waste-water-you-cant-ignore">
              <Button variant="primary" className='shadow'>SEE ALL QUESTIONS</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default MustRead