import { TextAndVideo } from './page-slices/text-and-video/index'
import { TextAndImage } from './page-slices/text-and-image/index'
import { Line } from './line'
import { Text } from './text'
import { InfoBannerImage } from './info-banner-image'
import { LineBetweenColumns } from './line-between-columns'
import { HeroBanner } from './page-slices/herobanner'
import { NavigationItem } from './header-slices/navigation_item'
import { DropDownItem } from './header-slices/dropdown_item'
import { DropDownText } from './header-slices/dropdown_text'
import { MuteText } from './page-slices/muted-text'
import { PageInfoBanner } from './page-slices/info-banner'
import { iFrameSlice } from './page-slices/iFrame-slice'
import { CareersBoard } from './page-slices/careers-board'
import { ContactPageSlice } from './page-slices/contact-page-slice'
import { ImageTextColumns } from './image-text-column'

//Article Component Imports
import { InfoBanner } from './article-slices/info-banner'
import { ArticleContent } from './article-slices/article-content'
import { BorderStart } from './article-slices/border-start'
import { MutedText } from './article-slices/muted-text'
import { Image } from './image'
import { Video } from './video'
import { ImageWrapText } from './article-slices/image-wrap-text/index'

//Homepage Component Imports
import { TextOverVideo } from './homepage-slices/text-over-video'
import { ImageGrid } from './homepage-slices/image-grid'
import { ArticlesAndEnews } from './homepage-slices/articles-and-enews'
import { InfoBannerHomepage } from './page-slices/info-banner'

import { Speaker } from './webinar-slices/speaker'


export const components = {
  page_info_banner: PageInfoBanner,
  page_mute_text: MuteText,
  text_and_image: TextAndImage,
  text_and_video: TextAndVideo,
  line: Line,
  multiple_column_text: Text,
  info_banner_image: InfoBannerImage,
  line_between_columns: LineBetweenColumns,
  hero_banner: HeroBanner,
  image_grid: ImageGrid,
  info_banner_homepage: InfoBannerHomepage,
  text: Text,
  navigation_item: NavigationItem,
  dropdown_text: DropDownText,
  dropdown_item: DropDownItem,
  iframe: iFrameSlice,
  careers_board: CareersBoard,
  contact_page_slice: ContactPageSlice,
  image_text_columns: ImageTextColumns,

  speaker: Speaker,

//Article Components
  article_content: ArticleContent,
  border_start: BorderStart,
  image: Image,
  info_banner: InfoBanner,
  muted_text: MutedText,
  video: Video,
  image_wrap_text: ImageWrapText,
  text_over_video: TextOverVideo,
  articles_and_enews: ArticlesAndEnews,

}