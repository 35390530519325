import * as React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { linkResolver } from '../../linkresolver'

export const ImageGrid = ({ slice }) => {
  return (
    <Container xxl>
      <Row lg={3} md={2} sm={1}>
        <Col sm className="hero-banner no-gutters">
          <GatsbyImage image={slice.primary.first_image.gatsbyImageData}
            className="hero-image"/>
          <div className='hero-fade'></div>
          <Link to={linkResolver(slice.primary.first_page_link.document)} className="hero-text hero-link">
            <div>{slice.primary.first_title.text}</div>
          </Link>
        </Col>

        <Col sm className="hero-banner no-gutters">
          <GatsbyImage image={slice.primary.second_image.gatsbyImageData}
            className="hero-image"/>
          <div className='hero-fade'></div>
          <Link to={linkResolver(slice.primary.second_page_link.document)} className="hero-text hero-link">
            <div>{slice.primary.second_title.text}</div>
          </Link>
        </Col>

        <Col sm className="hero-banner no-gutters">
          <GatsbyImage image={slice.primary.third_image.gatsbyImageData}
            className="hero-image"/>
          <div className='hero-fade'></div>
          <Link to={linkResolver(slice.primary.third_page_link.document)} className="hero-text hero-link">
            <div>{slice.primary.third_title.text}</div>
          </Link>
        </Col>
        
        <Col sm className="hero-banner no-gutters">
          <GatsbyImage image={slice.primary.fourth_image.gatsbyImageData}
            className="hero-image"/>
          <div className='hero-fade'></div>
          <Link to={linkResolver(slice.primary.fourth_page_link.document)} className="hero-text hero-link">
            <div>{slice.primary.fourth_title.text}</div>
          </Link>
        </Col>

        <Col sm className="hero-banner no-gutters">
          <GatsbyImage image={slice.primary.fifth_image.gatsbyImageData}
            className="hero-image"/>
          <div className='hero-fade'></div>
          <Link to={linkResolver(slice.primary.fifth_page_link.document)} className="hero-text hero-link">
            <div>{slice.primary.fifth_title.text}</div>
          </Link>
        </Col>

        <Col sm className="hero-banner no-gutters">
          <GatsbyImage image={slice.primary.sixth_image.gatsbyImageData}
            className="hero-image"/>
          <div className='hero-fade'></div>
          <Link to={linkResolver(slice.primary.sixth_page_link.document)} className="hero-text hero-link">
            <div>{slice.primary.sixth_title.text}</div>
          </Link>
        </Col>
      </Row>
    </Container>
  )
}