import * as React from 'react'
import { Link } from 'gatsby'
import { linkResolver } from '../linkresolver'

export const SliceButton = ({ button_link, button_text, colour, button_type }) => {
  //If passed a URL to internal, slice the string
  let uid = null
  let type = ''
  const domain = "https://wessuc.com/"

  if (!button_link.document && button_link.url) {
    const length = domain.length
    if (button_link.url.substr(0,length) === domain) {
      uid = button_link.url.slice(length)
    }
  }

  const variant = (colour === 'red') ? 'secondary' : 'primary'

  //Learn more buttons use a button outline
  if (button_type && button_type === 'Learn more') {
    type = `btn-outline-${variant}`
  } else {
    type = `btn-${variant}`
  }

  return (
    <div>              
      {button_link.document
        ?<Link to={linkResolver(button_link.document)} 
          className={`${type} btn shadow`}>
            {button_text}
        </Link>
        :(uid == null) 
          ? ''
          :<Link to={`/${uid}`} 
            className={`${type} btn shadow`}>{button_text}
          </Link>
      } 
    </div>
  )
}