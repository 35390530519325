import * as React from 'react'
import Col from 'react-bootstrap/Col'

export const CareersBoard = ({ slice }) => {
    const title = slice.primary.job_board_title.text;
    const url = slice.primary.url.text;

    const iFrame = `<div style=" text-align: center"><iframe style="width: 100%"
    id="careers-iframe"
    className="iframe-container"
    title=${title}
    src=${url}
    allowfullscreen="allowfullscreen"
  ></iframe></div>`;

  return (
    <Col>
        {<div dangerouslySetInnerHTML={{ __html: iFrame }} />}
    </Col>
  )
}