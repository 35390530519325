import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { components } from '../slices/index'

import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import Logo from '@assets/logo.svg'
import { PrismicRichText, SliceZone } from '@prismicio/react'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

const Header = () => {
  const header = useStaticQuery(graphql`
  query {
    prismicHeader {
      _previewable
      id
      data {
        emergency_text {
          richText
        }
        body {
          ... on PrismicHeaderDataBodyDropdownText {
            id
            slice_type
            primary {
              item_name {
                text
              }
              dropdown_text {
                richText
              }
              second_col_text {
              richText
            }
            first_col_text {
              richText
            }
            }
            items {
              align
              page_title {
                text
              }
              page_link {
                document {
                  ... on PrismicPage {
                    id
                    uid
                    internal {
                      type
                    }
                    data {
                      page_type
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicCaseStudy {
                    id
                    uid
                    internal {
                      type
                    }
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicArticle {
                    id
                    uid
                    internal {
                      type
                    }
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicWebinar {
                    id
                    uid
                    internal {
                      type
                    }
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
                url
              }
            }
          }
          ... on PrismicHeaderDataBodyDropdownItem {
            id
            slice_type
            primary {
              item_name {
                text
              }
            }
            items {
              page_title {
                text
              }
              page_link {
                document {
                  ... on PrismicPage {
                    id
                    uid
                    internal {
                      type
                    }
                    data {
                      page_type
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicCaseStudy {
                    id
                    uid
                    internal {
                      type
                    }
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicArticle {
                    id
                    uid
                    internal {
                      type
                    }
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicWebinar {
                    id
                    uid
                    internal {
                      type
                    }
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
                url
              }
            }
          }
          ... on PrismicHeaderDataBodyNavigationItem {
            id
            slice_type
            primary {
              item_name {
                text
              }
              item_link {
                url
                document {
                  ... on PrismicCaseStudy {
                    id
                    uid
                    internal {
                      type
                    }
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicPage {
                    id
                    uid
                    internal {
                      type
                    }
                    data {
                      page_type
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicWebinar {
                    id
                    uid
                    internal {
                      type
                    }
                    data {
                      title {
                        text
                      }
                    }
                  }
                  ... on PrismicArticle {
                    id
                    uid
                    internal {
                      type
                    }
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  `)
  const {data, isPreview} = useMergePrismicPreviewData(header)
  return (
    <Container fluid className='header position-sticky top'>
      <div className='emergency-banner'>
        <PrismicRichText field={data.prismicHeader.data.emergency_text.richText}/>
      </div>
      <Navbar expand='md'>
        <Container fluid="xxl">
          <Navbar.Brand>
            <Link to="/">
              <Logo className='logo' />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="header-navbar-nav" />
          <Navbar.Collapse id="header-navbar-nav">
            <Container fluid="xxl" className="header-navbar">
              <div>
                <Nav bsPrefix='wessuc-nav' navbarScroll>
                  <SliceZone slices={data.prismicHeader.data.body} components={components}/>
                </Nav>
              </div>
              <div>
                <Nav>
                  <Nav.Link className="contact-us">
                    <Link to="/contact">Contact Us</Link>
                  </Nav.Link>
                </Nav>
              </div>
            </Container>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  )
  }
export default Header
