import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Container, Row, Col } from "react-bootstrap";

export const Image = ({ slice }) => {
  const numImg = (slice.primary.num_columns > 1) ? slice.primary.num_columns : 1
  return (
    <Container className="section default-text">
      <Row xl={numImg} lg={numImg} md={numImg} sm={1} xs={1}>
        {slice.items.slice(0,numImg).map(({ image }) => (
          <Col className="text-center pb-4">
            <GatsbyImage image={image?.gatsbyImageData}/>
          </Col>
        ))}
      </Row>
    </Container>
  )
}