import * as React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import { SliceButton } from '@components/slice-button'
import { PrismicRichText } from '@prismicio/react'

export const LineBetweenColumns = ({ slice }) => {
  return (
    <Container fluid className="wastewater-audit-module bg-gray">
      <Container fluid="xxl">
        <Row xl={2} lg={2} md={2} sm={1} xs={1}>
          <Col className="text-center">
            <div class="module-half">
              <PrismicRichText field={slice.primary.slice_left_title.richText}/>
              <PrismicRichText field={slice.primary.slice_left_text.richText}/>
              {slice.primary.left_button_text.text
              ?<SliceButton button_link={slice.primary.left_button_link}
                button_text={slice.primary.left_button_text.text}
                button_type={slice.primary.left_button_type}
                >
              </SliceButton>
              :""
            }       
            </div>
          </Col>
          <Col className="text-center">
            <div class="module-half">
              <PrismicRichText field={slice.primary.slice_right_title.richText}/>
              <PrismicRichText field={slice.primary.slice_right_text.richText}/>
              {slice.primary.right_button_text.text
              ?<SliceButton button_link={slice.primary.right_button_link}
                button_text={slice.primary.right_button_text.text}
                button_type={slice.primary.right_button_type}
                >
              </SliceButton>
              :""
            }       
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default LineBetweenColumns
