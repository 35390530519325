import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { Container, Row, Col } from "react-bootstrap";

export const HeroBanner = ({ slice }) => {
  var hero_pos
  switch(slice.primary.hero_position) {
    case "Bottom":
      hero_pos = "center bottom"
      break;
    case "Top":
      hero_pos = "center top"
      break;
    case "Middle":
      hero_pos= "center middle"
      break;
    default:
      hero_pos = "center"
      break;
  };
  return (
    <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              {slice.primary.subtitle.richText.length
              ?<GatsbyImage
                className="hero-image"
                objectPosition={hero_pos}
                image={slice.primary.full_banner?.gatsbyImageData}
              />
              :<GatsbyImage
                className="hero-image"
                objectPosition={hero_pos}
                image={slice.primary.ratio_banner?.gatsbyImageData}
              />
              }

              <div className="hero-fade"></div>
              <div className="hero-text">
                <PrismicRichText field={slice.primary.banner_page_type?.richText}/>
                <PrismicRichText field={slice.primary.page_title?.richText}/>
                <PrismicRichText field={slice.primary.subtitle?.richText}/>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
  )
}