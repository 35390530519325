import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Layout from '@components/layout'
import WastewaterAudit from '@components/wastewater-audit'
import MustRead from '@components/must-read'
import { SliceZone } from '@prismicio/react'
import { components } from '../slices'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

const CaseStudy = ({ data }) => {
  return (
    <Layout pageTitle={data.prismicCaseStudy.data.title.text}>
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero light-hero">
              <GatsbyImage
                className="hero-image"
                image={data.prismicCaseStudy.data.image.gatsbyImageData}
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <div>
                  <h2>Case Study</h2>
                  <h1>{data.prismicCaseStudy.data.title.text}</h1>
                  <h3>{data.prismicCaseStudy.data.subtitle.text}</h3>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl">
          <Row xl={2} lg={2} md={1} sm={1} xs={1} className="default-text">
            <Col xl={8} lg={8} className="section">
              <SliceZone slices={data.prismicCaseStudy.data.body} components={components}/>
            </Col>
            <Col xl={4} lg={4} className="section">
              <div className="bottom-line">
                <div>THE BOTTOM LINE</div>
                <div>{data.prismicCaseStudy.data.bottom_line.text}</div>
              </div>
              <div className="metadata">
                <div>CLIENT</div>
                <div>{data.prismicCaseStudy.data.client.text}</div>
              </div>
              <div className="metadata">
                <div>PROCESS</div>
                <div>{data.prismicCaseStudy.data.process.text}</div>
              </div>
              <div className="metadata">
                <div>LOCATION</div>
                <div>{data.prismicCaseStudy.data.location.text}</div>
              </div>
              <div className="metadata">
                <div>EQUIPMENT</div>
                <div>{data.prismicCaseStudy.data.equipment.text}</div>
              </div>
              <div className="metadata">
                <div>PROJECT STATUS</div>
                <div>{data.prismicCaseStudy.data.project_status}</div>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
      <WastewaterAudit />
      <MustRead />
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    prismicCaseStudy(id: {eq: $id}) {
      _previewable
      data {
        title {
          text
        }
        subtitle {
          text
        }
        bottom_line {
          text
        }
        client {
          text
        }
        process {
          text
        }
        location {
          text
        }
        equipment {
          text
        }
        image {
          gatsbyImageData(aspectRatio: 2.5)
        }
        project_status
        body {
          ... on PrismicCaseStudyDataBodyVideo {
            id
            slice_type
          }
          ... on PrismicCaseStudyDataBodyText {
            id
            slice_type
            primary {
              num_columns
            }
            items {
              text_align
              paragraph {
                richText
              }
            }
          }
          ... on PrismicCaseStudyDataBodyImage {
            id
            slice_type
            primary {
              image {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(CaseStudy)