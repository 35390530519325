import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Container, Row, Col } from "react-bootstrap";
import { PrismicRichText } from '@prismicio/react';

export const ImageTextColumns = ({ slice }) => {
    console.log(slice.items)
  const cols = (slice.primary.num_columns > 1) ? slice.primary.num_columns : 1
  return (
    <Container className="section default-text">
      <Row xl={cols} lg={cols} md={cols} sm={1} xs={1}>
        {slice.items.map(({ image, text_under_image }) => (
          <Col>
            <GatsbyImage image={image?.gatsbyImageData}/>
            <PrismicRichText field={text_under_image.richText} />
          </Col>
        ))}
      </Row>
    </Container>
  )
}