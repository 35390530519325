exports.linkResolver = (doc) => {
  // URL for a category type
  if (doc === undefined) {return}
  
  if (doc.internal?.type === 'PrismicWebinar') {
    return `/webinars/${doc.uid}`
  }

  // URL for a product type
  if (doc.internal?.type === 'PrismicArticle') {
    return `/articles/${doc.uid}`
  }

  // URL for page types
  if (doc.internal?.type === 'PrismicPage') {
    if (doc.data?.page_type === 'none') {
      return `/${doc.uid}`
    } else {
      return `/${doc.data.page_type}/${doc.uid}`
    }
  }

  //URL for case studies
  if (doc.internal?.type === 'PrismicCaseStudy') {
    return `/case-studies/${doc.uid}`
  }
  
  // Backup for all other types
  return '/'
}