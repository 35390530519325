import * as React from 'react'

import Container from 'react-bootstrap/Container'


const lineStyle = {
  color: '#b9b9b9'
}

export const Line = props => {
  return (
    <Container>
    {props.slice.primary?.line_on
      ?<hr style={lineStyle}></hr>
      :""
      }
    </Container>
  )
}