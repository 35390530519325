import * as React from 'react'
import { PrismicRichText } from '@prismicio/react'
import { Container, Row, Col } from "react-bootstrap";

export const MuteText = ({ slice }) => {
  return (
    <Container>
      <Row>
        <Col className='text-muted'>
          <PrismicRichText field={slice.primary.muted_text.richText}/>
        </Col>
      </Row>
    </Container>
  )
}