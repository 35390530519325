exports.previewLinkResolver = (doc) => {
  // URL for a category type
  if (doc === undefined) {return}
  // URL for page types
  if (doc.type === 'page') {
    if (doc.data?.page_type === 'none') {
      return `/${doc.uid}`
    } else {
      return `/${doc.data?.page_type}/${doc.uid}`
    }
  }

  if (doc.type === 'webinar') {
    return `/webinars/${doc.uid}`
  }

  // URL for a product type
  if (doc.type === 'article') {
    return `/articles/${doc.uid}`
  }
          
  //URL for case studies
  if (doc.type === 'case_study') {
    return `/case-studies/${doc.uid}`
  }
  
  // Backup for all other types
  return '/'
}