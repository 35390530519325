import * as React from 'react'

import { RightVideo } from './right-video'
import { LeftVideo } from './left-video'

export const TextAndVideo = ({ slice }) => {
  switch(slice.primary.video_alignment) {
    case "Left":
      return( <LeftVideo slice={slice}/>)

    case "Right":
      return(<RightVideo slice={slice}/>)

    default:
      return(<RightVideo slice={slice}/>)
  }
}