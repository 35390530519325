import * as React from 'react'

import { RightImage } from './right-image'
import { LeftImage } from './left-image'

export const TextAndImage = ({ slice }) => {
  switch(slice.primary.image_alignment) {
    case "Left":
      return( <LeftImage slice={slice}/>)

    case "Right":
      return(<RightImage slice={slice}/>)

    default:
      return(<RightImage slice={slice} />)
  }
}