import { PrismicRichText } from '@prismicio/react'
import * as React from 'react'

export const BorderStart = ({ slice }) => {
  return (
    <p className="border-start border-info border-3 ps-3 ms-3 fs-2">
      <p className="fs-2">{slice.primary.text?.text}</p>
      <PrismicRichText  field={slice.primary.border_text?.richText}/>
    </p>
  )
}