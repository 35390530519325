import * as React from 'react'
import { Link } from 'gatsby'
import Dropdown from 'react-bootstrap/Dropdown'
import NavLink from 'react-bootstrap/NavLink'
import AngleDown from '@assets/angle-down.svg'
import NavItem from 'react-bootstrap/NavItem'
import { Col, Row, Container} from 'react-bootstrap'
import { PrismicRichText } from '@prismicio/react'
import AngleRight from '@assets/angle-right.svg'
import { linkResolver } from '../../linkresolver'

export const DropDownText = ({ slice }) => {
  return (
    <Dropdown as={NavItem}>
      <Dropdown.Toggle as={NavLink}>
        {slice.primary.item_name.text} <AngleDown className="angle-down" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Container className="special-nav" id="why-wessuc-dropdown">
          <Row>
            <Col>
              <PrismicRichText field={slice.primary.dropdown_text.richText}/>
            </Col>
          </Row>
          <Row>
            <Col sm>
            <PrismicRichText field={slice.primary.first_col_text.richText}/>
              <ul>
              {slice.items.map(({ page_link, page_title, align }) => 
                {if(align === 'Left') { 
                  return (
                    (page_link.document ? (
                      <li>
                          <Link to={linkResolver(page_link.document)}>
                          <span>{page_title.text
                              ? page_title.text
                              : page_link.document.data.title?.text}
                          </span>
                          <AngleRight className="angle-right"/>
                          </Link>
                      </li>
                      ) : (
                          <li>
                          <Link>
                          <span>{page_title.text
                              ? page_title.text
                              : page_link.document.data.title?.text}
                          </span>
                          <AngleRight className="angle-right"/>
                          </Link>
                      </li>
                      )
                    )
                  )
                }}
              )}
              </ul>
            </Col>
            <Col sm>
            <PrismicRichText field={slice.primary.second_col_text.richText}/>
              <ul>
              {slice.items.map(({ page_link, page_title, align }) => 
                {if(align === "Right") { 
                  return (
                    (page_link.document ? (
                      <li>
                        <Link to={linkResolver(page_link.document)}>
                          <span>{page_title.text
                            ? page_title.text
                            : page_link.document.data.title?.text}
                          </span>
                          <AngleRight className="angle-right"/>
                        </Link>
                      </li>
                      ) : (
                        <li>
                        <Link>
                          <span>{page_title.text
                            ? page_title.text
                            : page_link.document.data.title?.text}
                          </span>
                          <AngleRight className="angle-right"/>
                        </Link>
                      </li>
                      )
                    ) 
                  )
                }}
              )}
              </ul>
            </Col>
          </Row>
        </Container>
      </Dropdown.Menu>
    </Dropdown>
    
)
}
