import * as React from 'react'
import { Link } from 'gatsby'

export const Hyperlink = ({ node }) => {
  if (node.data?.url[0] && node.data.url[0] === '/') {
    return (
      <Link to={`${node.data.url}`}>{node.text}</Link>
    )
  } else {
    return (
      <a target='_blank' href={`${node.data.url}`}>{node.text}</a>
    )
  }
}