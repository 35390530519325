import * as React from 'react'
import { Link } from 'gatsby'
import { linkResolver } from '../../linkresolver'
import Dropdown from 'react-bootstrap/Dropdown'
import NavLink from 'react-bootstrap/NavLink'
import AngleDown from '@assets/angle-down.svg'
import NavItem from 'react-bootstrap/NavItem'

export const DropDownItem = ({ slice }) => {
  let sliceItems = slice.items

  sliceItems.forEach(({ page_link }) => {
    if (!page_link.document && page_link.url) {
      const regex = /https:\/\/wessuc\.com/;
      const str = page_link.url;
      const subst = '';
      // The substituted value will be contained in the result variable
      page_link.url = str.replace(regex, subst)
    }  
  })

  return (
    <Dropdown as={NavItem}>
      <Dropdown.Toggle as={NavLink}>
        {slice.primary.item_name.text} <AngleDown className="angle-down" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
      {sliceItems.map(({ page_link, page_title }) => 
        (page_link.document ? (
          <Link to={linkResolver(page_link.document)} className="dropdown-item">
            {page_title.text
            ? page_title.text
            : page_link.document.data.title?.text
            }
          </Link>
          ) : (
          <Link to={page_link.url} className="dropdown-item">
            {page_title.text
            ? page_title.text
            : page_link.document.data.title?.text
            }
          </Link>
          )
        )
      )}
      </Dropdown.Menu>
    </Dropdown>
  )
}
