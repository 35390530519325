import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import Layout from '@components/layout'
import { SliceZone } from '@prismicio/react'
import { components } from '../slices/index'

const IndexPage = ({ data }) => {
  return (
    <Layout pageTitle="Home">
        <SliceZone slices={data.prismicHomepage.data.homepage_link.document.data.body1} components={components}/>
    </Layout>
  )
}

export const query = graphql`
query  {
  prismicHomepage {
    _previewable
    data {
      homepage_link {
        document {
          ... on PrismicPage {
            id
            data {
              body1 {
                ... on PrismicPageDataBody1TextOverVideo {
                  id
                  slice_type
                  primary {
                    slice_title {
                      text
                    }
                    slice_text {
                      text
                    }
                    button_text {
                      text
                    }
                    video {
                      url
                    }
                    button_link {
                      document {
                        ... on PrismicWebinar {
                          _previewable
                          id
                          uid
                          internal {
                            type
                          }
                        }
                        ... on PrismicPage {
                          _previewable
                          id
                          uid
                          data {
                            page_type
                            title {
                              text
                            }
                          }
                          internal {
                            type
                          }
                        }
                        ... on PrismicCaseStudy {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                        ... on PrismicArticle {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                      }
                    }
                  }
                }
                ... on PrismicPageDataBody1Text {
                  id
                  slice_type
                  primary {
                    num_columns
                  }
                  items {
                    text_align
                    paragraph_title {
                      text
                    }
                    paragraph {
                      richText
                    }
                    button_text
                    button_link {
                      url
                      document {
                        ... on PrismicWebinar {
                          _previewable
                          id
                          uid
                          internal {
                            type
                          }
                        }
                        ... on PrismicPage {
                          _previewable
                          id
                          uid
                          data {
                            page_type
                            title {
                              text
                            }
                          }
                          internal {
                            type
                          }
                        }
                        ... on PrismicCaseStudy {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                        ... on PrismicArticle {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                      }
                    }
                  }
                }
                ... on PrismicPageDataBody1Line {
                  id
                  slice_type
                  primary {
                    line_on
                  }
                }
                ... on PrismicPageDataBody1InfoBannerHomepage {
                  id
                  slice_type
                  primary {
                    text_align
                    title_text {
                      text
                    }
                    text {
                      text
                    }
                    button_text {
                      text
                    }
                    button_link {
                      url
                      document {
                        ... on PrismicWebinar {
                          _previewable
                          id
                          uid
                          internal {
                            type
                          }
                        }
                        ... on PrismicPage {
                          _previewable
                          id
                          uid
                          data {
                            page_type
                            title {
                              text
                            }
                          }
                          internal {
                            type
                          }
                        }
                        ... on PrismicCaseStudy {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                        ... on PrismicArticle {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                      }
                    }
                  }
                }
                ... on PrismicPageDataBody1ArticlesAndEnews {
                  id
                  primary {
                    slice_title {
                      richText
                    }
                    slice_text {
                      text
                    }
                    button_text {
                      text
                    }
                    button_link {
                      url
                      document {
                        ... on PrismicWebinar {
                          _previewable
                          id
                          uid
                          internal {
                            type
                          }
                        }
                        ... on PrismicPage {
                          _previewable
                          id
                          uid
                          data {
                            page_type
                            title {
                              text
                            }
                          }
                          internal {
                            type
                          }
                        }
                        ... on PrismicCaseStudy {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                        ... on PrismicArticle {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                      }
                    }
                  }
                  items {
                    articles {
                      document {
                        ... on PrismicArticle {
                          id
                          uid
                          internal {
                            type
                          }
                          data {
                            title {
                              text
                            }
                            article_thumbnail {
                              gatsbyImageData(aspectRatio: 2.75)
                            }
                          }
                        }
                      }
                    }
                  }
                  slice_type
                }
                ... on PrismicPageDataBody1ImageGrid {
                  id
                  slice_type
                  primary {
                    first_title {
                      text
                    }
                    first_image {
                      gatsbyImageData(aspectRatio: 1.35)
                    }
                    first_page_link {
                      document {
                        ... on PrismicWebinar {
                          _previewable
                          id
                          uid
                          internal {
                            type
                          }
                        }
                        ... on PrismicPage {
                          _previewable
                          id
                          uid
                          data {
                            page_type
                            title {
                              text
                            }
                          }
                          internal {
                            type
                          }
                        }
                        ... on PrismicCaseStudy {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                        ... on PrismicArticle {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                      }
                    }
                    second_title {
                      text
                    }
                    second_image {
                      gatsbyImageData(aspectRatio: 1.35)
                    }
                    second_page_link {
                      document {
                        ... on PrismicWebinar {
                          _previewable
                          id
                          uid
                          internal {
                            type
                          }
                        }
                        ... on PrismicPage {
                          _previewable
                          id
                          uid
                          data {
                            page_type
                            title {
                              text
                            }
                          }
                          internal {
                            type
                          }
                        }
                        ... on PrismicCaseStudy {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                        ... on PrismicArticle {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                      }
                    }
                    third_title {
                      text
                    }
                    third_image {
                      gatsbyImageData(aspectRatio: 1.35)
                    }
                    third_page_link {
                      document {
                        ... on PrismicWebinar {
                          _previewable
                          id
                          uid
                          internal {
                            type
                          }
                        }
                        ... on PrismicPage {
                          _previewable
                          id
                          uid
                          data {
                            page_type
                            title {
                              text
                            }
                          }
                          internal {
                            type
                          }
                        }
                        ... on PrismicCaseStudy {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                        ... on PrismicArticle {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                      }
                    }
                    fourth_title {
                      text
                    }
                    fourth_image {
                      gatsbyImageData(aspectRatio: 1.35)
                    }
                    fourth_page_link {
                      document {
                        ... on PrismicWebinar {
                          _previewable
                          id
                          uid
                          internal {
                            type
                          }
                        }
                        ... on PrismicPage {
                          _previewable
                          id
                          uid
                          data {
                            page_type
                            title {
                              text
                            }
                          }
                          internal {
                            type
                          }
                        }
                        ... on PrismicCaseStudy {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                        ... on PrismicArticle {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                      }
                    }
                    fifth_title {
                      text
                    }
                    fifth_image {
                      gatsbyImageData(aspectRatio: 1.35)
                    }
                    fifth_page_link {
                      document {
                        ... on PrismicWebinar {
                          _previewable
                          id
                          uid
                          internal {
                            type
                          }
                        }
                        ... on PrismicPage {
                          _previewable
                          id
                          uid
                          data {
                            page_type
                            title {
                              text
                            }
                          }
                          internal {
                            type
                          }
                        }
                        ... on PrismicCaseStudy {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                        ... on PrismicArticle {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                      }
                    }
                    sixth_title {
                      text
                    }
                    sixth_image {
                      gatsbyImageData(aspectRatio: 1.35)
                    }
                    sixth_page_link {
                      document {
                        ... on PrismicWebinar {
                          _previewable
                          id
                          uid
                          internal {
                            type
                          }
                        }
                        ... on PrismicPage {
                          _previewable
                          id
                          uid
                          data {
                            page_type
                            title {
                              text
                            }
                          }
                          internal {
                            type
                          }
                        }
                        ... on PrismicCaseStudy {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                        ... on PrismicArticle {
                          _previewable
                          id
                          internal {
                            type
                          }
                          uid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`

export default withPrismicPreview(IndexPage)
