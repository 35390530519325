import * as React from 'react'
import { Link } from 'gatsby'
import { Nav } from 'react-bootstrap'
import { linkResolver } from '../../linkresolver'

export const NavigationItem = ({ slice }) => {
  //If passed a URL, slice the string
  const length = "https://wessuc.com/".length
  const uid = slice.primary.item_link.document ? null : slice.primary.item_link.url.slice(length)
  
  return (
    <Nav.Link>
      {slice.primary.item_link.document ? (
        <Link to={linkResolver(slice.primary.item_link.document)}>
          {slice.primary.item_name.text
          ? slice.primary.item_name.text
          : slice.primary.item_link.document.data.title?.text
          }
        </Link>
        ) : (
        <Link to={`/${uid}`}>
          {slice.primary.item_name.text
          ? slice.primary.item_name.text
          : slice.primary.item_link.document.data.title?.text
          }
        </Link>
        )
      }
    </Nav.Link>
  )
}
