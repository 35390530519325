import * as React from 'react'
import { graphql } from 'gatsby'

import Layout from '@components/layout'
import { SliceZone } from '@prismicio/react'
import { components } from '../slices/index'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { HeroBanner } from '../slices/page-slices/herobanner'
import { PageInfoBanner } from '../slices/page-slices/info-banner'

const PagePrismic = ({ data }) => {
  const pageData = data.prismicPage?.data
  /* If the first component in a page is a hero banner,
  set it outside of main tag to remove whitespace below nav bar */
  let heroBanner = false, slices = pageData.body

  if (pageData.body[0]?.slice_type === "hero_banner") {
    heroBanner = true  
    slices = pageData.body.slice(1)
  }

  /* If the last component in a page is an info banner,
  set it outside of main tag to remove whitespace above footer */
  let infoBanner = false
  if (slices[slices.length - 1]?.slice_type === 'page_info_banner') {
    infoBanner = true
    slices = slices.slice(0, -1)
  }
  return (
    <Layout pageTitle={pageData.title.text}>
      {heroBanner
      ? <HeroBanner slice={pageData.body[0]}/>
      : "" }
      <main>
        <SliceZone slices={slices} components={components}/>
      </main>
      {infoBanner
      ? <PageInfoBanner slice={pageData.body[pageData.body.length - 1]}/>
      : "" }
    </Layout>
  )
}

export const query = graphql`
query ($id : String!) {
  prismicPage (id: {eq: $id}){
    _previewable
    uid
    id
    data {
      page_type
      title {
        text
      }
      body {
        ... on PrismicPageDataBodyImage {
          id
          primary {
            num_columns
          }
          items {
            image {
              gatsbyImageData
            }
          }
          slice_type
        }
        ... on PrismicPageDataBodyVideo {
          id
          slice_type
          primary {
            video {
              url
            }
          }
        }
        ... on PrismicPageDataBodyPageMuteText {
          id
          slice_type
          primary {
            text_align
            muted_text {
              richText
            }
          }
        }
        ... on PrismicPageDataBodyHeroBanner {
          id
          slice_type
          primary {
            page_title {
              richText
            }
            subtitle {
              richText
            }
            banner_page_type {
              richText
            }
            hero_position
            ratio_banner: banner {
              gatsbyImageData(aspectRatio: 3.75, layout: FULL_WIDTH)
            }
            full_banner: banner {
              gatsbyImageData
            }
          }
        }
        ... on PrismicPageDataBodyLineBetweenColumns {
          id
          slice_type
          primary {
            slice_left_title {
              richText
            }
            slice_left_text {
              richText
            }
            right_button_type
            left_button_type
            left_button_text {
              text
            }
            left_button_link {
              url
              document {
                ... on PrismicPage {
                  _previewable
                  data {
                    title {
                      text
                    }
                  }
                  internal {
                    type
                  }
                  id
                  uid
                }
                ... on PrismicCaseStudy {
                  _previewable
                  internal {
                    type
                  }
                  id
                  uid
                }
                ... on PrismicArticle {
                  _previewable
                  id
                  uid
                  internal {
                    type
                  }
                }
                ... on PrismicWebinar {
                  _previewable
                  id
                  uid
                  internal {
                    type
                  }
                }
              }
            }
            slice_right_title {
              richText
            }
            slice_right_text {
              richText
            }
            right_button_text {
              text
            }
            right_button_link {
              url
              document {
                ... on PrismicWebinar {
                  _previewable
                  id
                  uid
                  internal {
                    type
                  }
                }
                ... on PrismicPage {
                  _previewable
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                  internal {
                    type
                  }
                }
                ... on PrismicCaseStudy {
                  _previewable
                  id
                  internal {
                    type
                  }
                  uid
                }
                ... on PrismicArticle {
                  _previewable
                  id
                  internal {
                    type
                  }
                  uid
                }
              }
            }
          }
        }
        ... on PrismicPageDataBodyPageInfoBanner {
          id
          slice_type
          primary {
            text_align
            text {
              richText
            }
            button_text {
              text
            }
            button_link {
              url
              document {
                ... on PrismicWebinar {
                  _previewable
                  id
                  uid
                  internal {
                    type
                  }
                }
                ... on PrismicPage {
                  _previewable
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                  internal {
                    type
                  }
                }
                ... on PrismicCaseStudy {
                  _previewable
                  id
                  internal {
                    type
                  }
                  uid
                }
                ... on PrismicArticle {
                  _previewable
                  id
                  internal {
                    type
                  }
                  uid
                }
              }
            }
          }
        }
        ... on PrismicPageDataBodyInfoBannerImage {
          id
          slice_type
          primary {
            title_text {
              richText
            }
            text {
              richText
            }
            image {
              gatsbyImageData
            }
            button_text {
              text
            }
            button_link {
              url
              document {
                ... on PrismicWebinar {
                  _previewable
                  id
                  uid
                  internal {
                    type
                  }
                }
                ... on PrismicPage {
                  _previewable
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                  internal {
                    type
                  }
                }
                ... on PrismicCaseStudy {
                  _previewable
                  id
                  internal {
                    type
                  }
                  uid
                }
                ... on PrismicArticle {
                  _previewable
                  id
                  internal {
                    type
                  }
                  uid
                }
              }
            }
          }
        }
        ... on PrismicPageDataBodyLine {
          id
          slice_type
          primary {
            line_on
          }
        }
        ... on PrismicPageDataBodyMultipleColumnText {
          id
          slice_label
          slice_type
          primary {
            num_columns
          }
          items {
            text_align
            paragraph {
              richText
            }
            paragraph_title {
              richText
              text
            }
            button_type
            button_text
            button_link {
              url
              document {
                ... on PrismicWebinar {
                  _previewable
                  id
                  uid
                  internal {
                    type
                  }
                }
                ... on PrismicPage {
                  _previewable
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                  internal {
                    type
                  }
                }
                ... on PrismicCaseStudy {
                  _previewable
                  id
                  internal {
                    type
                  }
                  uid
                }
                ... on PrismicArticle {
                  _previewable
                  id
                  internal {
                    type
                  }
                  uid
                }
              }
            }
          }
        }
        ... on PrismicPageDataBodyTextAndImage {
          id
          slice_type
          primary {
            image_alignment
            slice_title {
              richText
            }
            img_description {
              text
            }
            img {
              gatsbyImageData
              alt
            }
            id {
              text
            }
          }
          items {
            paragraph {
              richText
            }
            text_align
            button_type
            button_text
            button_link {
              url
              document {
                ... on PrismicWebinar {
                  _previewable
                  id
                  uid
                  internal {
                    type
                  }
                }
                ... on PrismicPage {
                  _previewable
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                  internal {
                    type
                  }
                }
                ... on PrismicCaseStudy {
                  _previewable
                  id
                  internal {
                    type
                  }
                  uid
                }
                ... on PrismicArticle {
                  _previewable
                  id
                  internal {
                    type
                  }
                  uid
                }
              }
            }
          }
        }
        ... on PrismicPageDataBodyTextAndVideo {
          id
          slice_type
          primary {
            video_alignment
            autoplay
            title_align
            slice_title {
              richText
            }
            vid {
              url
              link_type
            }
            vid_description {
              text
            }
          }
          items {
            paragraph {
              richText
            }
            text_align
            button_type
            button_text
            button_link {
              url
              document {
                ... on PrismicWebinar {
                  _previewable
                  id
                  uid
                  internal {
                    type
                  }
                }
                ... on PrismicPage {
                  _previewable
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                  internal {
                    type
                  }
                }
                ... on PrismicCaseStudy {
                  _previewable
                  id
                  internal {
                    type
                  }
                  uid
                }
                ... on PrismicArticle {
                  _previewable
                  id
                  internal {
                    type
                  }
                  uid
                }
              }
            }
          }
        }
        ... on PrismicPageDataBodyCareersBoard {
          id
          slice_type
          primary {
            job_board_title {
              text
            }
            url {
              text
            }
          }
        }
        ... on PrismicPageDataBodyIframe {
          id
          slice_type
          primary {
            iframe_title {
              text
            }
            url {
              text
            }
          }
        }
        ... on PrismicPageDataBodyContactPageSlice {
          id
          slice_type
          primary {
            contact_form_alignment
            drop_down_alignment
            contact_form_title {
              text
              richText
            }
            contact_form_url {
              richText
              text
            }
          }
          items {
            drop_down_field_title {
              richText
              text
            }
            drop_down_content {
              richText
              text
            }
          }
        }
        ... on PrismicPageDataBodyImageTextColumns {
          id
          slice_type
          primary {
            num_columns
          }
          items {
            image {
              gatsbyImageData
              alt
            }
            text_under_image {
              richText
            }
          }
        }
      }
    }
  }
}
`

export default withPrismicPreview(PagePrismic)
