import * as React from 'react'
import { textAlign } from '@components/text-align'
import { Col, Row, Container } from "react-bootstrap"
import { SliceButton } from '../../components/slice-button'
import { PrismicRichText } from '@prismicio/react'

export const InfoBanner = ({ slice }) => {
  return (
    <p className={`info-banner banner pad mb-0 ${textAlign(slice.primary.text_align)}`}>
      <Container>
        <Row>
          <Col xl={9} lg={9}>
            <PrismicRichText field={slice.primary.text?.richText}></PrismicRichText>
          </Col>
          <Col>  
            {slice.primary.button_text.text
              ?<SliceButton button_link={slice.primary.button_link}
                button_text={slice.primary.button_text.text}
                >
              </SliceButton>
              :""
            }
          </Col>
        </Row>
      </Container>
    </p>
  )
}