import * as React from 'react'
import Col from 'react-bootstrap/Col'
import embedLink from '@components/embed-link'

export const Video = ({ slice }) => {
  return (
    <Col className="text-center pb-4">
      <iframe
        id="webinar-video"
        frameborder="0"
        src={embedLink(slice.primary.video.url)}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" 
        allowfullscreen>
      </iframe>
    </Col>
  )
}