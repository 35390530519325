import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Header from '@components/header'
import Footer from '@components/footer'
import '@styles/main.scss'

const Layout = ({ pageTitle, pageDescription, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div>
        <title>{pageTitle} | {data.site.siteMetadata.title}</title>
        {pageDescription
          ? <meta name="description" content={pageDescription} />
          : '' 
        }
      <Header />
      {children}
      <Footer></Footer> 
    </div>
  )
}

export default Layout