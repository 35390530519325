import * as React from 'react'
import { PrismicRichText } from '@prismicio/react'
import { Col } from "react-bootstrap";

export const MutedText = ({ slice }) => {
  return (
    <Col className='text-muted'>
      <PrismicRichText field={slice.primary.muted_text.richText}/>
    </Col>
  )
}