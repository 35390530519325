import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { SliceButton } from '@components/slice-button'
import { textAlign } from '@components/text-align'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'


export const RightImage = ({ slice }) => {
  return (
    <main>
      <a id={slice.primary.id.text} />
    <Container className="section default-text">
      <Row className='align-items-center' xl={2} lg={2} md={2} sm={1} xs={1}>
        <Col>
          {slice.primary.slice_title.richText
          ? <PrismicRichText field={slice.primary.slice_title.richText}/>
          : ""
          } 
          {slice.items.map(({ paragraph, text_align, button_link, button_text, button_type }) => (
            <Col className={textAlign(text_align)}>
              <PrismicRichText field={paragraph.richText}/>
              {button_text
                ?<SliceButton button_type={button_type} 
                button_link={button_link} 
                button_text={button_text}></SliceButton>
                :""
              }
            </Col>
          ))}
        </Col>
        <Col>
          <GatsbyImage image={slice.primary.img.gatsbyImageData}/>
          <p className="media-description">{slice.primary.img_description.text}</p>
        </Col>
      </Row>
    </Container>
    </main>
  ) 
}