import * as React from 'react'
import { PrismicRichText } from '@prismicio/react'
import Col from 'react-bootstrap/Col'
import { textAlign } from '@components/text-align'

export const ArticleContent = ({ slice }) => {
  return (
    <Col className={textAlign(slice.primary.text_align)}>
      <PrismicRichText field={slice.primary.article_content.richText}/>
    </Col>
  )
}