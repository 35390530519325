import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { textAlign } from '@components/text-align'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export const RightImage = ({ slice }) => {
  return (
    <Row className='align-items-center'>
      <Col className={`ms-2 ${textAlign(slice.primary.text_align)}`}>
        <PrismicRichText field={slice.primary.wrap_text.richText}/>
      </Col>
      <Col className='d-flex justify-content-center me-2'>
        <GatsbyImage image={slice.primary.image.gatsbyImageData}/>
      </Col>
    </Row>
  )
}