import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { SliceZone } from '@prismicio/react'
import { components } from '../slices/index'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import { InfoBanner } from '../slices/article-slices/info-banner'
import Layout from '@components/layout'

const ArticlePage = ({ data }) => {
  const articleData = data.prismicArticle?.data
  let slices = articleData.body

  let infoBanner = false
  if (articleData.body[articleData.body.length - 1]?.slice_type === 'info_banner') {
    infoBanner = true
    slices = slices.slice(0, -1)
  }
  return (
    <div>
      <Layout pageTitle={articleData.title.text}>
        <Container fluid>
          <Row>
            <Col className="no-gutters">
              <div className="hero-banner large-hero light-hero">
                {articleData.article_thumbnail && articleData.article_thumbnail.gatsbyImageData
                  ?<GatsbyImage
                      className="hero-image"
                      image={articleData.article_thumbnail.gatsbyImageData}
                      alt=""
                      objectPosition="center"
                      layout="fullWidth" />
                  : ''
                }
                <div className="hero-fade"></div>
                <Container fluid="xxl" className="hero-div">
                  <div className="hero-text">
                    <h1>{articleData.title?.text}</h1>
                  </div>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
        <main>
         <Container className='section'>
           <Row>
             <Col className='article'>
              <SliceZone slices={slices} components={components}/>
             </Col>
           </Row>
         </Container>
        </main>
        {infoBanner
          ? <InfoBanner slice={articleData.body[articleData.body.length - 1]}/>
          : "" }
      </Layout>
    </div>
  )
}

export const query = graphql`
query ($id: String) {
  prismicArticle(id: {eq: $id}) {
    _previewable
    data {
      title {
        text
      }
      article_thumbnail {
        gatsbyImageData(aspectRatio: 3.75, layout: FULL_WIDTH)
      }
      body {
        ... on PrismicArticleDataBodyLine {
          id
          primary {
            line_on
          }
          slice_type
        }
        ... on PrismicArticleDataBodyText {
          id
          slice_type
          primary {
            num_columns
          }
          items {
            text_align
            paragraph {
              richText
            }
          }
        }
        ... on PrismicArticleDataBodyBorderStart {
          id
          slice_type
          primary {
            text {
              text
            }
            border_text {
              richText
            }
          }
        }
        ... on PrismicArticleDataBodyImageWrapText {
          id
          slice_type
          primary {
            text_align
            image_alignment
            wrap_text {
              richText
            }
            image {
              gatsbyImageData
            }
          }
        }
        ... on PrismicArticleDataBodyVideo {
          id
          slice_type
          primary {
            video {
              url
            }
          }
        }
        ... on PrismicArticleDataBodyImage {
          id
          primary {
            num_columns
          }
          items {
            image {
              gatsbyImageData
            }
          }
          slice_type
        }
        ... on PrismicArticleDataBodyInfoBanner {
          id
          slice_type
          primary {
            text_align
            text {
              richText
            }
            button_text {
              text
            }
            button_link {
              url
              document {
                ... on PrismicWebinar {
                  _previewable
                  id
                  uid
                  internal {
                    type
                  }
                }
                ... on PrismicPage {
                  _previewable
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                  internal {
                    type
                  }
                }
                ... on PrismicCaseStudy {
                  _previewable
                  id
                  internal {
                    type
                  }
                  uid
                }
                ... on PrismicArticle {
                  _previewable
                  id
                  internal {
                    type
                  }
                  uid
                }
              }
            }
          }
        }
        ... on PrismicArticleDataBodyMutedText {
          id
          slice_type
          primary {
            text_align
            muted_text {
              richText
            }
          }
        }
        ... on PrismicArticleDataBodyArticleContent {
          id
          slice_type
          primary {
            text_align
            article_content {
              richText
            }
          }
        }
      }
    }
  }
}
`

export default withPrismicPreview(ArticlePage)