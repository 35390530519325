import { PrismicRichText } from '@prismicio/react';
import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import '@styles/accordion.scss'
import { ListGroup } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

export const ContactPageSlice = ({ slice }) => {
    console.log(slice)
    const formTitle = slice.primary.contact_form_title.text;
    const formUrl = slice.primary.contact_form_url.text;
    const formAlign = slice.primary.contact_form_alignment;
    const dropDownAlign = slice.primary.contact_dropdown_alignment;

    const contactIFrame = `<div style="text-align: ${formAlign}"><iframe 
    width= "100%"
    frameborder="0"
    id="contact-form"
    className="placeholder-iframe"
    title=${formTitle}
    src=${formUrl}
  ></iframe></div>`;

    let count = 0;

    return (
        <Container fluid="xxl" className="section">
            <Row xl={2} lg={2} md={1} sm={1} xs={1}>
                <Col md>
                    <div style={{ textAlign: dropDownAlign }}>
                        <Accordion defaultActiveKey='1'>
                            {slice.items.map(({ drop_down_field_title, drop_down_content }) => (
                                count++,
                                <Accordion.Item eventKey={count.toString()}>
                                    <Accordion.Header onClick={(e) => e.currentTarget.blur()}>{drop_down_field_title.text}</Accordion.Header>
                                    <Accordion.Body className='ps-5'>
                                        <Container fluid="xxl">
                                            <ListGroup variant="flush">
                                                <ListGroup.Item>
                                                    <div class="accordion-contact">
                                                    <PrismicRichText field={drop_down_content.richText} />
                                                    </div>
                                                </ListGroup.Item>
                                            </ListGroup>
                                        </Container>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </div>
                    <div className="pt-4">
                        <Button href="https://zfrmz.com/8AysjGm07rfdaXks6HpA?city=Sarnia" variant="secondary" className='shadow' target='_blank'>Shire - Lagoon complaint form</Button>
                    </div>
                </Col>
                <Col md>
                    <div dangerouslySetInnerHTML={{ __html: contactIFrame }} />
                </Col>
            </Row>
        </Container>
    )
}