import * as React from 'react'
import { PrismicRichText } from '@prismicio/react'
import * as prismicH from '@prismicio/helpers'
import { components } from './richtext-helpers/index'

import { textAlign } from '@components/text-align'
import { Container, Row, Col } from "react-bootstrap";
import { SliceButton } from '@components/slice-button'

export const Text = ({ slice }) => {
  const num = (slice.primary.num_columns > 1) ? slice.primary.num_columns : 1
  let sliceItems = slice.items

  sliceItems.forEach(item => {
    item.paragraph.richText.forEach(richText => {
      richText.spans.forEach(span => {
        //Only look for hyperlinks to documents
        if (span?.type === 'hyperlink') {
          if (span.data?.link_type === 'Document') {
            //get pagetype by delimeters
            let pageType = ''
            let start = richText?.text.indexOf('/*')
            let end = richText?.text.lastIndexOf('*/')
            start = (start !== -1) ? start : richText.text.length
            end = (end !== -1) ? end : start
            pageType = richText.text.slice(start + 2, end)
            if (pageType !== '') {
              pageType = `/${pageType}`
            }

            //Delete delimeters from the text
            richText.text = richText.text.slice(0, start) + richText.text.slice(end + 2)

            //Set hyperlink data
            span.data = {
              link_type: 'Web',
              url: `${pageType}/${span.data.uid}`
            }
          }
        }
      })
    })
  })
    
  return (
    <Container className='section'>
      <Row xl={num} lg={num} md={num} sm={1} xs={1}>
        {sliceItems.slice(0,num)
        .map(({ paragraph, paragraph_title, button_text, button_link, text_align, button_type }) => (
          <Col className={textAlign(text_align)}>
              <a id={paragraph_title?.text} />
            {paragraph_title?.richText
              ? <PrismicRichText field={paragraph_title.richText}/>
              : ""
            }
            {prismicH.isFilled.richText(paragraph.richText) && (
              <PrismicRichText field={paragraph.richText} components={components} />
            )}
            {button_text
              ?<SliceButton button_type={button_type} button_link={button_link} button_text={button_text}></SliceButton>
              :""
            }
          </Col>
        ))}
      </Row>
    </Container>
  )
}