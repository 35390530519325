import * as React from 'react'
import Col from 'react-bootstrap/Col'
import embedLink from '@components/embed-link'

export const Video = (link) => {
  return (
    <Col className="text-center">
      <iframe
        id="youtube-video"
        frameborder="0"
        src={embedLink(link.link)}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" 
        allowfullscreen>
      </iframe>
    </Col>
  )
}