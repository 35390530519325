import * as React from 'react'
import { Link } from 'gatsby'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import WessucLogo from '@assets/logo.svg'
import TwitterIcon from '@assets/twitter-icon.svg'
import YoutubeIcon from '@assets/youtube-icon.svg'
import InstagramIcon from '@assets/instagram-icon.svg'
import LinkedInIcon from '@assets/linkedin-icon.svg'

const Footer = () => {
  return (
    <Container fluid className="footer">
      <Container fluid="xxl">
        <Row className="align-items-center">
          <Col md>
            <div className="socials">
              <div>
                <div>
                  <a href="https://twitter.com/wessuc">
                    <TwitterIcon />
                  </a>
                </div>
                <div>
                  <a href="https://www.youtube.com/user/WessucInc">
                    <YoutubeIcon />
                  </a>
                </div>
                <div>
                  <a href="https://www.instagram.com/wessuc.inc/">
                    <InstagramIcon />
                  </a>
                </div>
                <div>
                  <a href="https://www.linkedin.com/company/wessuc-inc/">
                    <LinkedInIcon />
                  </a>
                </div>
              </div>
              <div>
                <WessucLogo className="logo" />
              </div>
            </div>
          </Col>
          <Col md className="section">
            <div className="center">
              <div>1693 Colborne St. E.</div>
              <div>Brantford, ON N3T 5L4</div>
              <div>
                <a href="mailto:info@wessuc.com">info@wessuc.com</a>
              </div>
              <div>
                <a href="tel:1866-493-7782">1.866.493.7782</a>
              </div>
            </div>
          </Col>
          <Col md className="section">
            <div className="center">
              <div>
                <Link to="/our-team">Our Team</Link>
              </div>
              <div>
                <Link to="/our-approach">Our Approach</Link>
              </div>
              <div>
                <Link to="/careers">Careers</Link>
              </div>
              <div>
                <Link to="/aoda">A.O.D.A.</Link>
              </div>
              <div>
                <Link to="/faq">FAQ</Link>
              </div>
            </div>
          </Col>
          <Col md>
            <div>
              <div className="emergency-services">
                <div className="twenty-four-seven">24/7</div>
                <div>Emergency Services</div>
                <a href="tel:1866-493-7782">1-866-493-7782</a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Footer