import * as React from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import { SliceButton } from '@components/slice-button'

export const InfoBannerImage = ({ slice }) => {
  return (
    <Container className="section text-center must-read">
      <Container fluid="xxl">
        <Row xl={2} lg={2} md={2} sm={1} xs={1} className="align-items-center">
          <Col>
           <GatsbyImage image={slice.primary.image.gatsbyImageData}/>
          </Col>
          <Col>
            <PrismicRichText field={slice.primary.title_text.richText}/>
            <PrismicRichText field={slice.primary.text.richText}/>
            {slice.primary.button_text.text
              ?<SliceButton button_link={slice.primary.button_link}
                button_text={slice.primary.button_text.text}
                >
              </SliceButton>
              :""
            }          
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default InfoBannerImage
